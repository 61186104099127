import NETWORK from '..'
import marketListMainnet from './market_list_mainnet.json'
import marketListTestnet from './market_list_testnet.json'
import marketListBaseGoerli from './market_list_base_goerli.json'
import marketListBaseSepolia from './market_list_base_sepolia.json'
import marketListFantomTest from './market_list_fantom_testnet.json'
import marketListFantomSonic from './market_list_fantom_sonic.json'
import marketListMumbai from './market_list_mumbai.json'
import marketListAvalancheFuji from './market_list_avalanche_fuji.json'
import marketListModeTestnet from './market_list_mode_testnet.json'
import marketListAnvil from './market_list_anvil.json'
import marketListBase from './market_list_base.json'

const marketList = {
  mainnet: marketListMainnet,
  testnet: marketListTestnet,
  baseGoerli: marketListBaseGoerli,
  baseSepolia: marketListBaseSepolia,
  fantomTestnet: marketListFantomTest,
  fantomSonic: marketListFantomSonic,
  polygonMumbai: marketListMumbai,
  avalancheFuji: marketListAvalancheFuji,
  modeTestnet: marketListModeTestnet,
  base: marketListBase,
}

const marketListByChainId = {
  71402: marketListMainnet,
  71401: marketListTestnet,
  84531: marketListBaseGoerli,
  84532: marketListBaseSepolia,
  4002: marketListFantomTest,
  64240: marketListFantomSonic,
  80001: marketListMumbai,
  43113: marketListAvalancheFuji,
  919: marketListModeTestnet,
  31337: marketListAnvil,
  8453: marketListBase,
}

export function getMarketList(chainId) {
  // console.log('getMarketList 11111', marketListByChainId)
  if (marketListByChainId[chainId]) {
    // console.log('getMarketList 22222', marketListByChainId[chainId])
    return marketListByChainId[chainId]
  }
  // console.log('getMarketList 3333', marketListMainnet)
  return marketListMainnet
}

export default marketList[NETWORK]
