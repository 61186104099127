<template>
  <li @click="connect">
    <a href="#" class="flex items-center justify-center p-3 text-base font-bold text-gray-900 bg-gray-600 rounded hover:bg-gray-100 group hover:shadow">
      <img :src="walletconnect" class="h-[28px]" alt="" />
      <span class="flex-1 ml-3 whitespace-nowrap">WalletConnect</span>
    </a>
  </li>
</template>

<script>
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig } from '@wagmi/core'
import { arbitrum, mainnet, polygon } from '@wagmi/core/chains'
import { watchAccount } from '@wagmi/core'
import walletconnect from '../../assets/walletconnect.svg'
import store from '../../store'

const projectId = 'b0d1678a1deb7707e0d9fb843b700152'
export default {
  data: function () {
    return {
      modal: null,
      account: null,
      walletconnect: walletconnect,
    }
  },
  async mounted() {
    const chains = [arbitrum, mainnet, polygon]
    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient,
    })
    const ethereumClient = new EthereumClient(wagmiConfig, chains)
    this.modal = await new Web3Modal({ projectId }, ethereumClient)
    // this.modal = await new Web3Modal({ projectId, walletConnectVersion: 2 })
    watchAccount((account) => {
      console.log(account)
      this.account = account
      if (account.isConnected) store.commit('setAccount', account?.address)
    })
  },
  methods: {
    connect: async function () {
      await this.modal.openModal()
      // this.$emits('close')
    },
  },
}
</script>
