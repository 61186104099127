const NETWORK = {
  31337: {
    chainId: `0x${Number(31337).toString(16)}`,
    rpcUrls: ['http://localhost:9545'],
    chainName: 'Anvil Local',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://v1.testnet.gwscan.com'],
  },
  71401: {
    chainId: `0x${Number(71401).toString(16)}`,
    rpcUrls: ['https://godwoken-testnet-v1.ckbapp.dev'],
    chainName: 'Godwoken Testnet',
    nativeCurrency: {
      name: 'pCKB',
      symbol: 'pCKB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://v1.testnet.gwscan.com'],
  },
  71402: {
    chainId: `0x${Number(71402).toString(16)}`,
    rpcUrls: ['https://v1.mainnet.godwoken.io/rpc'],
    chainName: 'Godwoken Mainnet',
    nativeCurrency: {
      name: 'pCKB',
      symbol: 'pCKB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://gwscan.com/'],
  },
  84531: {
    chainId: `0x${Number(84531).toString(16)}`,
    rpcUrls: ['https://goerli.base.org'],
    chainName: 'Base Goerli',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://goerli.basescan.org'],
  },
  84532: {
    chainId: `0x${Number(84532).toString(16)}`,
    rpcUrls: ['https://sepolia.base.org'],
    chainName: 'Base Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://sepolia-explorer.base.org'],
  },
  8453: {
    chainId: `0x${Number(8453).toString(16)}`,
    rpcUrls: ['https://mainnet.base.org'],
    chainName: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  4002: {
    chainId: `0x${Number(4002).toString(16)}`,
    rpcUrls: ['https://rpc.testnet.fantom.network/'],
    chainName: 'Fantom Testnet',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.ftmscan.com'],
  },
  64240: {
    chainId: `0x${Number(64240).toString(16)}`,
    rpcUrls: ['https://rpcapi.sonic.fantom.network/'],
    chainName: 'Fantom Sonic Open',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
    blockExplorerUrls: ['https://public-sonic.fantom.network'],
  },
  534351: {
    chainId: `0x${Number(534351).toString(16)}`,
    rpcUrls: ['https://sepolia-rpc.scroll.io/'],
    chainName: 'Scroll Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://sepolia.scrollscan.com'],
  },
  534352: {
    chainId: `0x${Number(534352).toString(16)}`,
    rpcUrls: ['https://rpc.scroll.io/'],
    chainName: 'Scroll',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://scrollscan.com/'],
  },
  80001: {
    chainId: `0x${Number(80001).toString(16)}`,
    rpcUrls: ['https://public.stackup.sh/api/v1/node/polygon-mumbai'],
    chainName: 'Polygon Mumbai',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
  },
  43113: {
    chainId: `0x${Number(43113).toString(16)}`,
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    chainName: 'Avalanche Fuji Testnet',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.snowtrace.io'],
  },
  919: {
    chainId: `0x${Number(919).toString(16)}`,
    rpcUrls: ['https://sepolia.mode.network'],
    chainName: 'Mode Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://sepolia.explorer.mode.network'],
  }
}

export default NETWORK