<template>
  <TransitionRoot appear :show="showWallet" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center w-96 mx-auto">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full max-w-md transform overflow-hidden rounded bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <div class="text-white">Connect wallet</div>
                <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="crypto-modal" @click="onClose">
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </DialogTitle>
              <div class="p-6 h-full">
                <ul class="my-4 space-y-3">
                  <MetaMask @close="closeModal" />
                  <!-- <SmartWallet @close="closeModal" /> -->
                  <Coinbase @close="closeModal" />
                  <JoyId @close="closeModal" />
                  <WalletConnect2 @close="closeModal" />
                </ul>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import store from '../store'
import UseWallet from '../utils/wallet'
import JoyId from './wallet/Joyid.vue'
import MetaMask from './wallet/MetaMask.vue'
import Coinbase from './wallet/Coinbase.vue'
import SmartWallet from './wallet/SmartWallet.vue'
import WalletConnect2 from './wallet/WalletConnect.vue'
import metaMask from '../assets/metamask.svg'

const props = defineProps(['showWallet'])
const emits = defineEmits(['close'])

function closeModal() {
  emits('close')
}

function show() {
  showWallet.value = true
}

function onClose() {
  closeModal()
}
</script>

<style></style>
